import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { SH2 } from "../../styled/SText";
import Device from "../media/Device";
import OrderItem from "../parts/OrderItem";
import orderMobileBg from "../../images/asaFotPhone.png";
import orderTabletBg from "../../images/asaForTablet.png";
import orderDesktopBg from "../../images/asaForDesktop.png";
const Order = () => {
  const orderItems = [
    {
      img: (
        <StaticImage
          placeholder="tracedSVG"
          alt="通常注文"
          src="../../images/tuujo.svg"
        />
      ),
      link: "normal-order",
      title: "通常注文",
      text: "お茶会でのご利用、自宅への発送、ご贈答としてのご利用などはこちら",
    },
    // {
    //   img: (
    //     <StaticImage
    //       placeholder="tracedSVG"
    //       alt="定期注文"
    //       src="../../images/teiki.svg"
    //     />
    //   ),
    //   link: "/order/teiki",
    //   title: "定期注文",
    //   text: "これはダミーテキストです。実際の内容は後日書きます。",
    // },
    {
      img: (
        <StaticImage
          placeholder="tracedSVG"
          alt="通販"
          src="../../images/tuuhan.svg"
        />
      ),
      link: "online-shop",
      title: "通販",
      text: "不定期に季節の上生菓子セットを販売します。",
    },
  ];
  return (
    <StyledOrder>
      <div className="order__title">
        <StyledOrderTitle>ご注文方法</StyledOrderTitle>
      </div>
      <div className="order__container--bg">
        <div className="order__container">
          <ul className="order__lists">
            {orderItems.map((item, index) => (
              <OrderItem key={item.title + index} itemData={item} />
            ))}
          </ul>
        </div>
      </div>
    </StyledOrder>
  );
};
const StyledOrder = styled.div`
  margin-top: var(--margin-topMoblile);

  @media ${Device.tablet} {
    margin-top: var(--margin-toptablet);
  }
  @media ${Device.laptop} {
    margin-top: var(--margig-topDesk);
  }
  .order {
    &__container {
      &--bg {
        background-image: url(${orderMobileBg});
        background-repeat: no-repeat;
        background-size: cover;
        @media ${Device.tablet} {
          background-image: url(${orderTabletBg});
          padding: 2rem 0 0 0;
        }
        @media ${Device.laptop} {
          background-image: url(${orderDesktopBg});
        }
      }
    }
    &__title {
      margin-bottom: 5.5rem;
      text-align: center;
      @media ${Device.tablet} {
        margin-bottom: 4rem;
      }
      @media ${Device.laptop} {
        margin-bottom: 3rem;
      }
    }
    &__lists {
      max-width: var(--max-width);
      width: 90vw;
      margin: 0 auto;
      transform: translateY(5rem);
      display: flex;
      flex-direction: column;
      gap: 4.5rem;
      align-items: center;
      @media ${Device.tablet} {
        flex-direction: row;
        gap: 10rem;
        margin: 0 auto;
        justify-content: center;
      }
      @media ${Device.laptop} {
        transform: translateY(4rem);
        gap: 10rem;
      }
    }
  }
`;
const StyledOrderTitle = styled(SH2)``;
export default Order;
