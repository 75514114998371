import React from "react";
import styled from "styled-components";
import Device from "../media/Device";

const NewsItem = ({ item }) => {
  const dateForTime = item.date.replaceAll("/", "-");
  const dateForText = item.date.replaceAll("/", ".");
  return (
    <>
      {item.link ? (
        <StyledLinkedLi>
          <a
            href={item.link}
            className="newsItem__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <time dateTime={dateForTime} className="newsItem__date">
              {dateForText}
            </time>
            <span className="newsItem__text">{item.text}</span>
          </a>
        </StyledLinkedLi>
      ) : (
        <StyledLi>
          <time dateTime={dateForTime} className="newsItem__date">
            {dateForText}
          </time>
          <span className="newsItem__text">{item.text}</span>
        </StyledLi>
      )}
    </>
  );
};
const StyledLi = styled.li`
  display: flex;
  flex-direction: column;
  @media ${Device.tablet} {
    flex-direction: row;
  }
  .newsItem {
    &__date,
    &__text {
      line-height: 2.4rem;
      letter-spacing: var(--ls-normal);
    }
    &__text {
      color: var(--gray-color);
    }
    &__date {
      margin-right: 2rem;
      margin-bottom: 0.8rem;
      color: var(--gray-color);
      @media ${Device.tablet} {
        margin-bottom: 0;
      }
    }
  }
`;

const StyledLinkedLi = styled.li`
  .newsItem {
    &__date,
    &__text {
      line-height: 2.4rem;
      letter-spacing: var(--ls-normal);
    }
    &__link {
      transition: all 0.3s;
      display: flex;
      flex-direction: column;

      @media ${Device.tablet} {
        flex-direction: row;
      }
      .newsItem__text {
        color: var(--gray-color);
        transition: all 0.3s;
        text-decoration: underline;
        @media ${Device.tablet} {
          text-decoration: none;
        }
      }
      .newsItem__date {
        margin-right: 2rem;
        color: var(--gray-color);
        transition: all 0.3s;
        margin-bottom: 0.8rem;
        @media ${Device.tablet} {
          margin-bottom: 0;
        }
      }
      &:hover {
        opacity: 0.8;
        @media ${Device.tablet} {
        }
        .newsItem__text,
        .newsItem__date {
          color: var(--sub-color);
        }
      }
    }
  }
`;
export default NewsItem;
