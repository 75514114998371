import React from "react";
import styled from "styled-components";
import Device from "../media/Device";

const HeroText = () => {
  const words = [
    { word: "自然の", size: "small" },
    { word: "美しさ", size: "big" },
    { word: "や", size: "small" },
    { word: "味覚", size: "big" },
    { word: "を", size: "small" },
    { word: "和菓子", size: "big" },
    { word: "に", size: "small" },
  ];
  return (
    <StyledHeroText>
      {words.map((item, index) => (
        <span key={item.word + index} className={`${item.size}`}>
          {item.word}
        </span>
      ))}
    </StyledHeroText>
  );
};

const StyledHeroText = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  align-items: center;
  letter-spacing: 0.2em;
  gap: 0.3rem;
  @media ${Device.tablet} {
    letter-spacing: 0.5em;
    gap: 1rem;
    top: 70%;
    left: 52%;
  }
  @media (orientation: landscape) and (max-width: 930px) {
    top: 80%;
  }
  .big,
  .small {
    font-weight: bold;
    /* color: var(--black-color); */
    white-space: nowrap;
  }
  .big {
    font-size: 2.2rem;
    @media ${Device.tablet} {
      font-size: 3.2rem;
    }
    @media (orientation: landscape) and (max-width: 930px) {
      font-size: 2.8rem;
    }
  }
  .small {
    font-size: 1.3rem;
    @media ${Device.tablet} {
      font-size: 2.4rem;
    }
    @media (orientation: landscape) and (max-width: 930px) {
      font-size: 2rem;
    }
  }
`;
export default HeroText;
