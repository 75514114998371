import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { accordionIsOpen } from "../../../state/state";

const AccordionBaseItem = ({ question, answer }) => {
  //useState
  const setOpen = useSetRecoilState(accordionIsOpen);
  const [isOpen, setIsOpen] = useState(false);
  const toggleClass = isOpen ? "open" : "";
  //function
  const onClickHandler = () => {
    setIsOpen((pre) => !pre);
    setOpen((pre) => !pre);
  };
  return (
    <StyeldContentLi
      className={`accordion ${toggleClass}`}
      onClick={onClickHandler}
    >
      <div className={`question ${toggleClass}`}>
        <p className="title">{question}</p>
        <span className="bar"></span>
      </div>
      <p className={`answer ${toggleClass}`}>{answer}</p>
    </StyeldContentLi>
  );
};
const StyeldContentLi = styled.li`
  border: 1px solid var(--sub-color);
  width: 100%;
  border-radius: 2px;
  background-color: transparent;
  transition: all var(--transition-3s);
  cursor: pointer;
  overflow: hidden;
  /* &:hover {
    border: 1px solid rgba(183, 161, 94, 0.5);
    .bar {
      background-color: rgba(183, 161, 94, 0.5);
      &::after {
        background-color: rgba(183, 161, 94, 0.5);
      }
    }
  } */

  &.open {
    border: 1px solid var(--red-color);
    background-color: var(--white-color);

    .title {
    }
    .bar {
      background-color: var(--red-color);
      &::after {
        transform: rotate(360deg);
        background-color: var(--red-color);
      }
    }
  }
  .question {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 3rem 2.5rem;
    border: none;
    font-size: 1.6rem;
    cursor: pointer;
    background-color: transparent;
  }
  .title {
    transition: all 0.2s;
    &::selection {
      background-color: transparent;
    }
  }
  .bar {
    width: 3.1rem;
    height: 2px;
    position: relative;
    background-color: var(--sub-color);
    transition: all var(--transition-3s);
    &::after {
      position: absolute;
      content: "";
      transform: rotate(90deg);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--sub-color);
      transition: all var(--transition-3s);
    }
  }
  .answer {
    transition: all var(--transition-3s);
    height: 0;
    padding: 0 2.5rem;
    line-height: 2.8rem;
    text-align: left;
    font-size: 1.5rem;
  }
`;
export default AccordionBaseItem;
