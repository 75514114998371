import React from "react";
import styled from "styled-components";
import Device from "../media/Device";

const Leading = () => {
  return (
    <StyledLeading>
      <div>
        <p>小さな菓子に</p>
        <p>ひとつひとつ心を込めて</p>
        <p>華やかに繊細に</p>
        <p>季節を閉じ込めました</p>
        <p>手にとって</p>
        <p>味わって</p>
        <p>空に羽ばたくような</p>
        <p>清々しいひとときを</p>
      </div>
    </StyledLeading>
  );
};
const StyledLeading = styled.div`
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15rem;
  letter-spacing: 0.2em;
  @media (orientation: landscape) and (max-width: 930px) {
    margin-top: 28rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    justify-content: center;
    gap: 9rem;
    letter-spacing: 0.5em;
    flex-wrap: nowrap;
  }
  @media ${Device.tablet} {
    margin-top: 28rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    justify-content: center;
    gap: 9rem;
    letter-spacing: 0.5em;
  }
  div {
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
    @media (orientation: landscape) and (max-width: 930px) {
      white-space: pre;
      writing-mode: vertical-rl;
      display: flex;
      text-align: start;
      flex-direction: column;
    }
    @media ${Device.tablet} {
      display: flex;
      flex-direction: column;
      text-align: start;
      writing-mode: vertical-rl;
    }
  }
  p {
    line-height: 4rem;
    @media ${Device.tablet} {
      line-height: 6rem;
    }
  }
`;
export default Leading;
