import React from "react";
import styled from "styled-components";
import { newsListData } from "../../data/newsListData";
import { SH2 } from "../../styled/SText";
import Device from "../media/Device";
import Button from "../parts/Button";
import NewsItem from "../parts/NewsItem";

const News = () => {
  return (
    <StyledNews>
      <div className="container">
        <div className="text-container">
          <div className="title">
            <StyledNewsTitle>新着情報</StyledNewsTitle>
          </div>
          <StyledUl>
            {newsListData
              .sort((a, b) => (a.date < b.date ? 1 : -1))
              .slice(0, 4)
              .map((item, index) => (
                <NewsItem key={item.text + index} item={item} />
              ))}
          </StyledUl>
        </div>
        <Button link="/new-information/">View More</Button>
      </div>
    </StyledNews>
  );
};
const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media ${Device.tablet} {
    gap: 2rem;
  }
`;

const StyledNews = styled.div`
  margin-left: auto;
  background-color: var(--black-color);
  margin-top: var(--margin-topMoblile);
  padding: 2rem var(--padding);
  @media ${Device.tablet} {
    padding: 4.75rem var(--padding) 1.5rem;
    margin-top: var(--margin-toptablet);
  }
  @media ${Device.laptop} {
    padding: 4.75rem calc((100vw - 108rem) / 2) 1.5rem var(--padding);
    max-width: var(--right-box);
    margin-top: var(--margig-topDesk);
  }
  .container {
    display: flex;
    flex-direction: column;
    max-width: 108rem;
    margin-left: auto;
  }
  .text-container {
    @media ${Device.tablet} {
      display: flex;
      gap: 4.5rem;
    }
  }

  .title {
    border-bottom: 1px solid var(--gray-color);
    margin-bottom: 2rem;
    @media ${Device.tablet} {
      border-bottom: none;
      margin-bottom: 0;
      border-right: 1px solid var(--gray-color);
      padding: 2rem 4.5rem 0 0;
    }
  }
  ul {
    @media ${Device.tablet} {
      padding: 2rem 0 2rem;
    }
  }
`;
const StyledNewsTitle = styled(SH2)`
  color: var(--gray-color);
  margin-bottom: 1rem;
  white-space: nowrap;
`;
export default News;
