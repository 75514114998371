import React from "react";
import styled from "styled-components";
import Device from "../media/Device";
import mainTextImage from "../../images/hero-title.png";
import subTextImage from "../../images/hero-sub-title2.png";
const Logo = () => {
  return (
    <StyledLogo className="title">
      <div className="sub-img"></div>
      <div className="main-img"></div>
    </StyledLogo>
  );
};
const StyledLogo = styled.div`
  position: absolute;
  top: 40%;
  left: 51%;
  transform: translate(calc(-50% + 1.2rem), -50%);
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  .main-img {
    background-image: url(${mainTextImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(14rem * 1);
    width: calc(6.5rem * 1);
  }
  .sub-img {
    background-image: url(${subTextImage});
    height: calc(9rem * 1);
    width: calc(2.5rem * 1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${Device.tablet} {
    .main-img {
      height: calc(14rem * 1.3);
      width: calc(6.5rem * 1.3);
    }
    .sub-img {
      height: calc(9rem * 1.3);
      width: calc(2.5rem * 1.3);
    }
  }
  @media (orientation: landscape) and (max-width: 930px) {
    .main-img {
      height: calc(14rem * 0.9);
      width: calc(6.5rem * 0.9);
    }
    .sub-img {
      height: calc(9rem * 0.9);
      width: calc(2.5rem * 0.9);
    }
  }
`;

export default Logo;
