import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/section/Hero";
import Leading from "../components/section/Leading";
import News from "../components/section/News";
import Order from "../components/section/Order";
import Qa from "../components/section/Qa";
import Wagashi from "../components/section/Wagashi";
import { Seo } from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Leading />
      <News />
      <Wagashi />
      <Order />
      <Qa />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <Seo></Seo>;
