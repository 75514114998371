import React from "react";
import styled from "styled-components";
import { SH2 } from "../../styled/SText";
import Device from "../media/Device";
import AccordionBase from "../parts/accordion/AccordionBase";

const Qa = () => {
  return (
    <StyledQa>
      <div className="qa__title">
        <StyledQaTitle>よくある質問</StyledQaTitle>
      </div>
      <AccordionBase />
    </StyledQa>
  );
};
const StyledQa = styled.div`
  margin-top: var(--margin-topMoblile);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${Device.tablet} {
    margin-top: var(--margin-toptablet);
  }
  @media ${Device.laptop} {
    margin-top: var(--margig-topDesk);
  }
  .qa {
    &__title {
      margin-bottom: 8rem;
    }
  }
`;
const StyledQaTitle = styled(SH2)``;
export default Qa;
