import React from "react";
import styled from "styled-components";
import HeroText from "../parts/HeroText";
import Logo from "../parts/Logo";
import heroMobileImg from "../../images/mobile-top.jpg";
import heroDesktopImg from "../../images/TOPshusei.jpg";
import Device from "../media/Device";
const Hero = () => {
  return (
    <StyledHero>
      <Logo />
      <HeroText />
    </StyledHero>
  );
};

const StyledHero = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: #ddd;
  background-image: url(${heroMobileImg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (orientation: landscape) and (max-width: 930px) {
    background-image: url(${heroDesktopImg});
  }
  @media ${Device.tablet} {
    background-image: url(${heroDesktopImg});
  }
`;
export default Hero;
