import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Device from "../media/Device";

const OrderItem = ({ itemData }) => {
  const { img, title, text, link } = itemData;
  return (
    <StyledOrderItem>
      <Link className="item__link" to={`how-to-order/${link}/`}>
        <div className="item__img">{img}</div>
        <h3 className="item__title">{title}</h3>
        <p className="item__text">{text}</p>
      </Link>
    </StyledOrderItem>
  );
};

const StyledOrderItem = styled.li`
  position: relative;
  width: 30rem;
  height: 34rem;
  background-color: var(--white-color);
  border-radius: 3px;
  border: 1px solid rgba(206, 197, 170, 0.5);
  transition: all var(--transition-3s);
  box-shadow: 0 7px 20px -15px var(--shadow-color),
    0 30px 30px -25px var(--shadow-color);
  /* &:not(:last-child) {
    &::after {
      @media ${Device.tablet} {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        width: 1px;
        height: 80%;
        transform: translateY(-50%);
        background-color: var(--lightGray-color);
      }

      @media ${Device.laptop} {
        background-color: transparent;
      }
    }
  } */

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 14px 20px -20px var(--shadow-color),
      0 50px 30px -30px var(--shadow-color);
  }
  &:active {
    box-shadow: none;
    transform: scale(0.95);
  }

  @media ${Device.tablet} {
    border-radius: 3px;
    border: 1px solid rgba(206, 197, 170, 0.5);
    transition: all var(--transition-3s);
    box-shadow: 0 10px 10px -15px var(--shadow-color),
      0 40px 30px -15px var(--shadow-color);
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      box-shadow: none;
      transform: scale(0.95);
    }
  }
  .item {
    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.7rem;
      padding: 4.5rem 2.5rem;
      @media ${Device.tablet} {
      }
    }
    &__img {
      width: 17rem;
      height: 14rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__title {
      font-size: 2rem;
      font-weight: bold;
    }
    &__text {
      line-height: 2.4rem;
    }
  }
`;
export default OrderItem;
