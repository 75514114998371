import React from "react";
import styled from "styled-components";
import { wagashiItemData } from "../../data/wagashiItemData";
import { SH2 } from "../../styled/SText";
import Device from "../media/Device";
import Button from "../parts/Button";
import Witems from "../parts/Witems";

const Wagashi = () => {
  const thisMonth = new Date().getMonth() + 1;
  const wagashiOfTheMonth = wagashiItemData.filter(
    (ob) => ob.month === thisMonth && ob.display
  );
  return (
    <StyledWagashi>
      <div className="wagashi__container">
        <div className="wagashi__title">
          <StyledWagashiTitle>今月の和菓子</StyledWagashiTitle>
        </div>
        <Witems data={wagashiOfTheMonth} />
        <Button black="true" link={"/okashi/"}>
          View More
        </Button>
      </div>
    </StyledWagashi>
  );
};
const StyledWagashi = styled.div`
  margin-top: var(--margin-topMoblile);
  @media ${Device.tablet} {
    margin-top: var(--margin-toptablet);
  }
  @media ${Device.laptop} {
    margin-top: var(--margig-topDesk);
  }
  .wagashi {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      max-width: var(--max-width);
    }
    &__title {
      margin-bottom: 8rem;
    }
  }
`;
const StyledWagashiTitle = styled(SH2)``;

export default Wagashi;
