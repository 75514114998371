import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { accordionIsOpen } from "../../../state/state";
import AccordionBaseItem from "./AccordionBaseItem";

const AccordionBase = () => {
  const list = [
    {
      question: "どこで購入できますか？",
      answer:
        "実店舗が無い為、通信販売、宝庵・たからの庭イベントでの販売や工房販売、ワカミヤスタンドさんにて委託販売をお願いしております。販売の際はFacebook,Instagramでお知らせ致します。",
    },
    {
      question: "オーダーのお菓子はお願いできますか？",
      answer:
        "お茶会や講座などテーマに沿ったお菓子をご提案しております。イメージ、ご注文数など遅くても2ヶ月前にはご相談下さい。（他のご注文で混み合っている場合にはお受けできない事もございます。",
    },
    {
      question: "商品の受け渡し、支払い方法は？",
      answer:
        "基本的にクール冷凍便にてお届けいたします。お支払いは、クレジット決済、または銀行振込でお願い致します。",
    },
  ];
  const open = useRecoilValue(accordionIsOpen);

  useEffect(() => {
    const question = document.querySelectorAll(".accordion");
    question.forEach((item) => {
      item.addEventListener("click", () => {
        const answer = item.querySelector(".answer");
        console.log(answer.scrollHeight);
        if (!answer.classList.contains("open")) {
          answer.style.height = answer.scrollHeight + 20 + "px";
        } else {
          answer.style.height = 0;
        }
      });
    });
  }, [open]);
  return (
    <StyledAccordionUl>
      {list.map((item, index) => (
        <AccordionBaseItem
          key={item.question + index}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </StyledAccordionUl>
  );
};
const StyledAccordionUl = styled.ul`
  margin: 0 auto;
  width: 90vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: var(--max-width);
`;
export default AccordionBase;
